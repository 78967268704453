import { lazy } from "react";

const FormIndex = lazy(() => import("../components/LeadFormSteps/FormIndex"));
const PsychologyListing = lazy(() => import("../Container"));

export const routePath = [
    {
        name: "Home",
        path: "/",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "General Psychology",
        path: "/psychology/generalpsychology",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Addictions & Recovery",
        path: "/psychology/addictions&recovery",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Applied Psychology",
        path: "/psychology/appliedpsychology",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Behavioral Psychology",
        path: "/psychology/behavioralpsychology",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Child Psychology",
        path: "/psychology/childpsychology",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Counseling",
        path: "/psychology/counseling",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Forensic Psychology",
        path: "/psychology/forensicpsychology",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Human Services",
        path: "/psychology/humanservices",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Organizational Psychology",
        path: "/psychology/organizationalpsychology",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Sociology",
        path: "/psychology/sociology",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Business",
        path: "/business/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Criminal Justice",
        path: "/criminal-justice/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Psychology",
        path: "/psychology/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Computers & IT",
        path: "/computers-&-technology/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Healthcare",
        path: "/health,-nursing,-medicine/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Education & Teaching",
        path: "/education-&-teaching/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Vocational Training",
        path: "/vocational-training/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Liberal Arts",
        path: "/art-&-architecture/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "General",
        path: "/general/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Nursing",
        path: "/nursing/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Media & Communications",
        path: "/media-&-communications/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Media & Communications",
        path: "/media-&-communications/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Visual Arts & Graphic Design",
        path: "/visual-arts-&-graphic-design/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "Religious Studies",
        path: "/religious-studies/:id?",
        exact: true,
        component: PsychologyListing
    },
    {
        name: "LeadForm",
        path: "/get-information",
        exact: true,
        component: FormIndex
    },
    {
        name: "Page Not Found",
        path: "*",
        exact: true,
        component: PsychologyListing
    },
]
